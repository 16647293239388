<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="类型" prop="kind">
        <el-radio-group v-model="form.kind" size="small"  @change="country">
          <el-radio-button label="0">国内</el-radio-button>
          <el-radio-button label="1">海外</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="发货点名字" prop="name">
        <el-input v-model="form.name" size="small" />
      </el-form-item>
      <div v-if="domestic">
        <el-form-item style="margin-bottom: 20px" label="省市区" prop="selectedOptions">
          <el-cascader
              v-model="form.selectedOptions"
              :options="apiRegionList"
              :props="{ expandTrigger: 'hover' }"
              size="small"
              style="width: 100%"
          >
            <template slot-scope="{ node, data }">
              <span>{{ data.label }}</span>
              <span v-if="!node.isLeaf">({{ data.children.length }})</span>
            </template>
          </el-cascader>
        </el-form-item>
        <el-form-item style="margin-bottom: 20px" label="详细地址" prop="contact_address">
          <el-input v-model="form.contact_address" size="small" />
        </el-form-item>
      </div>
      <div v-if="overseas">
        <el-form-item style="margin-bottom: 20px;" label="国家" prop="country">
          <el-select
              v-model="form.country"
              clearable
              placeholder="请选择"
              size="small"
              style="width: 100%;"
          >
            <el-option
                v-for="item in apiCountry"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item style="margin-bottom: 20px" label="地址1" prop="contact_address">
          <el-input v-model="form.contact_address" size="small" />
        </el-form-item>
        <el-form-item style="margin-bottom: 20px" label="地址2" prop="contact_address2">
          <el-input v-model="form.contact_address2" size="small" />
        </el-form-item>
      </div>
      <el-form-item style="margin-bottom: 20px" label="联系电话" prop="mobile">
        <el-input v-model="form.mobile" size="small" />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="邮政编码" prop="zip_code">
        <el-input v-model="form.zip_code" size="small" />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="默认发货点" prop="is_deliver_goods">
        <el-switch
            v-model="form.is_list"
            active-color="#13ce66"
            active-text="默认收货点"
            active-value="1"
            inactive-color="#ff4949"
            inactive-text="非默认收货点"
            inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="默认退货点" prop="is_return_goods">
        <el-switch
            v-model="form.is_return_goods"
            active-color="#13ce66"
            active-text="默认退货点"
            active-value="1"
            inactive-color="#ff4949"
            inactive-text="非默认退货点"
            inactive-value="0"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'Edit',
    data() {
      return {
        domestic: true,
        overseas: false,
        apiRegionList: [],
        apiCountry: [],
        form: {
          kind: '0',
          name: '',
          selectedOptions: '',
          contact_address: '',
          contact_address2: '',
          country: '',
          mobile: '',
          zip_code: '',
          is_deliver_goods: '',
          is_return_goods: '',
        },
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入发货点名字' },
          ],
          mobile: [
            { required: true, trigger: 'blur', message: '请输入联系电话' },
            {
              pattern: /^[0-9]*$/,
              trigger: 'blur',
              message: '联系电话只能为数字',
            },
            {
              min: 11,
              max: 11,
              message: '请输入11位手机号码',
              trigger: 'blur',
            },
          ],
          zip_code: [
            { required: true, trigger: 'blur', message: '请输入邮政编码' },
            {
              pattern: /^[0-9]*$/,
              trigger: 'blur',
              message: '邮政编码只能为数字',
            },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {
      this.requestRegionList()
      this.requestCountry()
    },
    methods: {
      // 选择国内/国外
      country(selectValue) {
        if (selectValue == 0) {
          this.domestic = true
          this.overseas = false
        } else {
          this.domestic = false
          this.overseas = true
        }
      },
      // 获取省份三级联动
      async requestRegionList() {
        const { data } = await regionList()
        // console.log(data)
        this.apiRegionList = data
      },
      // 国家
      async requestCountry() {
        const { data } = await matchBranchList()
        this.apiCountry = data
      },
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '修改'
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { code, message } = await doEdit(this.form)
            if (code == '200') {
              this.$message.success(message)
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$message.error(message)
              this.$emit('fetch-data')
              this.close()
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
