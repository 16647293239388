<template>
  <el-card class="box-card">
    <vab-query-form-left-panel  :span="12">
      <el-button icon="el-icon-plus" type="warning" @click="handleEdit" size="small">新增</el-button>
    </vab-query-form-left-panel>

    <el-table
        :data="list"
        style="width: 100%">
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="name"
          label="名称">
      </el-table-column>
      <el-table-column
          align="center"
          :formatter="formatterIsDeliverGoods"
          prop="is_deliver_goods"
          label="默认发货点">
      </el-table-column>
      <el-table-column
          align="center"
          :formatter="formatterIsReturnGoods"
          prop="is_return_goods"
          label="默认退货点">
      </el-table-column>
      <el-table-column
          align="center"
          prop="contact"
          label="联系人">
      </el-table-column>
      <el-table-column
          align="center"
          prop="addr"
          label="地址">
      </el-table-column>
      <el-table-column
          align="center"
          prop="mobile"
          label="电话">
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit" />
  </el-card>
</template>
<script>
import Edit from './components/Edit'

export default {
  components: { Edit },
  props: [],
  data() {
    return {
      selectRows: '',
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
      },
      list: [
        {
          id: '1',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '0',
        },
        {
          id: '2',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '1',
        },
        {
          id: '3',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '2',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const { code, message } = await doDelete({ id: row.id })
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const { code, message } = await doDelete({ id })
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const { data } = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },

    // 收货点
    formatterIsDeliverGoods: function (row) {
      return row.is_deliver_goods == '1'
        ? '默认收货点'
        : row.is_deliver_goods == '0'
        ? '非默认收货点'
        : '暂无'
    },
    // 退货点
    formatterIsReturnGoods: function (row) {
      return row.is_return_goods == '1'
        ? '默认退货点'
        : row.is_return_goods == '0'
        ? '非默认退货点'
        : '暂无'
    },
  }
}

</script>
<style>
  .el-pagination {
    text-align: center;
    margin-top: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-dropdown {
    margin-right: 10px;
  }

  .page {
    background-color: #FFF;
    padding: 20px;
    margin: 20px;
  }

  .el-row {
    margin-bottom: 15px;
  }
</style>
